// feature flag context provider and hook

import { createContext, useContext } from 'react'

import type { ActiveFeatureFlag } from '@server/models/flag/featureFlag.constants'

export type FeatureFlagContextType = Partial<{
  // Currently boolean flags, but we could extend this to include other types
  [key in ActiveFeatureFlag]: boolean
}>

export const FeatureFlagContext = createContext<FeatureFlagContextType>({})

/**
 * Hook to check if a feature flag is enabled
 * @param flagName - The name of the feature flag to check
 * @returns true if the feature flag is enabled, false otherwise
 */
export const useFeatureFlag = (
  flagName: ActiveFeatureFlag,
  fallbackValue: boolean = false,
) => {
  const context = useContext(FeatureFlagContext)
  return flagName in context ? context[flagName] : fallbackValue
}
