import { useContext } from 'react'

import { Button, ButtonIcon, cn, Text } from '@optechai/design-system'
import type { ButtonIconProps } from '@optechai/design-system/src/components/ui/button-icon/button-icon'
import {
  AngryIcon,
  FrownIcon,
  InfoIcon,
  LaughIcon,
  MehIcon,
  SmileIcon,
  ThumbsUpIcon,
} from 'lucide-react'
import { observer } from 'mobx-react-lite'

import type { CsatScore } from '@server/models/ticket/ticket.dto'

import { TicketContext } from '../tickets/ticket/context'

type CsatFormProps = {
  value: CsatScore | undefined
  onChange: (value: CsatScore) => void
}
export const RATING_LABELS = {
  1: {
    label: 'Very dissatisfied',
    icon: AngryIcon,
    variant: 'rating-negative',
    className: 'border-none',
  },
  2: {
    label: 'Dissatisfied',
    icon: FrownIcon,
    className: 'text-orange-600 hover:bg-orange-200',
    variant: undefined,
  },
  3: {
    label: 'Neutral',
    icon: MehIcon,
    className: '',
    variant: undefined,
  },
  4: {
    label: 'Satisfied',
    icon: SmileIcon,
    className: 'text-green-600 hover:bg-green-200',
    variant: undefined,
  },
  5: {
    label: 'Very satisfied',
    icon: LaughIcon,
    variant: 'rating-positive',
    className: 'border-none',
  },
} satisfies Record<CsatScore, Partial<ButtonIconProps>>

export const CsatForm = ({ value, onChange }: CsatFormProps) => {
  return (
    <div className="flex flex-col items-center gap-y-s">
      <Text color="subtle" variant="p-xs">
        Rate your conversation
      </Text>
      <ol className="mx-auto flex max-w-60 gap-xxs rounded border border-border p-s">
        {Object.entries(RATING_LABELS).map(([score, options]) => (
          <li key={score}>
            <ButtonIcon
              className={cn(options.className)}
              icon={options.icon}
              label={options.label}
              onClick={() => onChange(parseInt(score) as CsatScore)}
              size="large"
              state={value === parseInt(score) ? 'selected' : 'default'}
              variant={'variant' in options ? options.variant : undefined}
            />
          </li>
        ))}
      </ol>
    </div>
  )
}

export const DidThisHelp = observer(() => {
  const ticketStore = useContext(TicketContext)

  if (!ticketStore) {
    return null
  }

  if (ticketStore.isReadOnly) {
    return null
  }

  return (
    <>
      {ticketStore.csatDidThatHelp === undefined && (
        <div className={cn('flex flex-col items-center gap-y-s')}>
          <Text color="subtle" variant="p-xs">
            Did this answer your question?
          </Text>
          <div className="flex gap-1">
            <Button
              leftIcon={ThumbsUpIcon}
              onClick={() => ticketStore.update({ csatDidThatHelp: true })}
              type="button"
              value="YES"
              variant="outline"
            >
              This helped
            </Button>
            <Button
              leftIcon={InfoIcon}
              onClick={() => ticketStore.update({ csatDidThatHelp: false })}
              type="button"
              value="NO"
              variant="outline"
            >
              I need more help
            </Button>
          </div>
        </div>
      )}
      {ticketStore.csatDidThatHelp !== undefined && (
        <Text
          className={cn('text-center', 'opacity-0', 'animate-fade-in')}
          color="subtle"
          variant="p-xs"
        >
          Thanks! Your feedback helps us improve.
        </Text>
      )}
    </>
  )
})
