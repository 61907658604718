import {
  type PillVariantTypes,
  getNumberDisplay,
} from '@optechai/design-system'
import { formatISO } from 'date-fns'

import type { Customer } from '../../../server/src/models/customer/customer.dto'
import type { User } from '../../../server/src/models/user/user.dto'
import type { UserForQA } from '../../../server/src/services/ticket/ticket'
export const getPercentageDisplay = (
  percentage: number | undefined,
  isSuccess?: (percentage: number) => boolean,
): { value: number | null; displayText: string; variant: PillVariantTypes } => {
  return {
    displayText: getNumberDisplay(percentage ?? null, '%'),
    value: percentage ?? null,
    variant:
      percentage === undefined
        ? 'grey'
        : isSuccess?.(percentage)
          ? 'success'
          : 'error',
  }
}

export const getUserDisplayName = (
  person: User | Customer | UserForQA | null | undefined,
  fallback: string = '',
) => {
  if (!person) return fallback

  if ('displayName' in person && person.displayName) {
    return person.displayName
  }

  if (person.firstName || person.lastName) {
    return [person.firstName, person.lastName].filter(Boolean).join(' ')
  }

  if (person.email) {
    return person.email
  }

  return person.remoteId ?? fallback
}

// Because the date is passed from a searchParam, catch any parsing errors.
export const formatDateFromUrl = (
  date: string | null,
  defaultDate: Date,
): string => {
  if (date !== null) {
    try {
      return formatISO(date)
    } catch (error) {}
  }
  return formatISO(defaultDate)
}
