import { Loader2 } from 'lucide-react'

import { cn } from '../../../lib/utils'

/**
 * __TypingLoader__
 *
 * Loader component to show a loading animation for typing.
 *
 * @example
 * <TypingLoader />
 */
export const TypingLoader = ({ className }: { className?: string }) => (
  <svg
    className={cn('h-5', className)}
    name="loader"
    preserveAspectRatio="xMidYMid"
    viewBox="20 45 45 20"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <circle className="fill-disabled" cx="27.5" cy="57.5" r="5">
      <animate
        attributeName="cy"
        begin="-0.6s"
        calcMode="spline"
        dur="1s"
        keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
        keyTimes="0;0.3;0.6;1"
        repeatCount="indefinite"
        values="57.5;49.5;57.5;57.5"
      ></animate>
    </circle>
    <circle className="fill-disabled" cx="42.5" cy="57.5" r="5">
      <animate
        attributeName="cy"
        begin="-0.45s"
        calcMode="spline"
        dur="1s"
        keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
        keyTimes="0;0.3;0.6;1"
        repeatCount="indefinite"
        values="57.5;49.5;57.5;57.5"
      ></animate>
    </circle>
    <circle className="fill-disabled" cx="57.5" cy="57.5" r="5">
      <animate
        attributeName="cy"
        begin="-0.3s"
        calcMode="spline"
        dur="1s"
        keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
        keyTimes="0;0.3;0.6;1"
        repeatCount="indefinite"
        values="57.5;49.5;57.5;57.5"
      ></animate>
    </circle>
  </svg>
)

/**
 * __Loader__
 *
 * Generic spinning loader.
 */
export const Loader = () => {
  return <Loader2 className="min-w-4 shrink-0 animate-spin" size={16} />
}
