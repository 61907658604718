import { cn, Text } from '@optechai/design-system'
import { LorikeetLogoMark } from '@optechai/design-system/src/components/ui/logos/lorikeet-logo'

interface PoweredByProps {
  variant?: 'default' | 'subtle'
}

/**
 * __PoweredBy__
 *
 * Branded footer for the application. Can also be used as inline branding message.
 */
export const PoweredBy = ({ variant = 'default' }: PoweredByProps) => (
  <div
    className={cn(
      'flex items-center justify-center',
      variant === 'default' && 'gap-x-xxs bg-neutral px-m py-s',
    )}
  >
    {variant === 'default' && <LorikeetLogoMark className="size-5 grayscale" />}
    <Text color="subtle" variant="p-xs">
      <a
        className="text-subtle"
        href="https://lorikeetcx.ai"
        rel="noreferrer"
        target="_blank"
      >
        Powered by Lorikeet
      </a>
    </Text>
  </div>
)
